import React from 'react';

const Map = ({ width, height }) => {
  return (
    <div className="map">
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2617.423709222475!2d14.761037651418674!3d49.00252349839947!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x477334340438c3cf%3A0xa12f89c921fc15ce!2zVWJ5dG92w6Fuw60gIlUgU3bEm3RhIiBUxZllYm_FiA!5e0!3m2!1sen!2scz!4v1520116231910"
        frameBorder="0"
        style={{ border: 0, width, height }}
        allowFullScreen={true}
        title="Mapa Ubytování U Světa"
      />
    </div>
  );
};

export default Map;
