import React from 'react';
import Helmet from 'react-helmet';
import { graphql } from 'gatsby';

import Layout from '../components/layout';
import { HTMLContent } from '../components/Content';
import Map from '../components/Map';
import Address from '../components/Address';

export const ContactPageTemplate = ({ content, email, phone }) => (
  <div className="contact">
    <div className="row">
      <div className="col-12">
        <HTMLContent content={content} />
      </div>
    </div>

    <div className="row">
      <div className="col-12 col-md-6">
        <div className="mb-3">
          <b>Telefon:</b>
          <br />
          {phone}
        </div>

        <div className="mb-3">
          <Address />
        </div>
      </div>

      <div className="col-12 col-md-6">
        <Map width="100%" height="300px" />
      </div>
    </div>
  </div>
);

export default ({ data, location }) => {
  const { markdownRemark: page, site } = data;

  return (
    <Layout location={location}>
      <Helmet
        title={`${site.siteMetadata.title} | ${page.frontmatter.title}`}
      />

      <ContactPageTemplate
        content={page.html}
        email={page.frontmatter.email}
        phone={page.frontmatter.phone}
      />
    </Layout>
  );
};

export const pageQuery = graphql`
  query ContactPageQuery($path: String!) {
    markdownRemark(frontmatter: { urlPath: { eq: $path } }) {
      html
      frontmatter {
        title
        phone
        email
      }
    }

    site {
      siteMetadata {
        title
      }
    }
  }
`;
