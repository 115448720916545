import React from 'react';

const Address = () => {
  return (
    <div className="address">
      <b>Adresa:</b>
      <br />
      Přemyslova 124
      <br />
      Třeboň
      <br />
      379 01
      <br />
    </div>
  );
};

export default Address;
